import 'slick-carousel';

$(function () {
    // top_banner_pcのカルーセル用jQuery
    $('.top-banner-pc-carousel').slick({
        arrows: false,
        autoplay: true,
        adaptiveHeight: true,
        centerMode: true,
        centerPadding: "15%",
        autoplaySpeed: 3000,
        // dots: true,
    });
    

    // top_banner_spのカルーセル用jQuery
    $('.top-banner-sp-carousel').slick({
        arrows: false,
        autoplay: true,
        adaptiveHeight: true,
        centerMode: true,
        centerPadding: "10%",
        autoplaySpeed: 3000,
    });
});
